import React, {useState} from 'react';
import {CSSTransition} from "react-transition-group";
import classes from "./addBalance.module.css";
import {texts} from "../../../../translate";
import {cryptocloud, plisio, stars} from "../../../../utils/api";
import {useTelegram} from "../../../../hooks/useTelegram";
import Loading from "../loading/loading";

const AddBalance = ({lang, openMenu, setOpenMenu}) => {
    const {webApp} = useTelegram()
    const [load, setLoad] = useState(false)

    async function clickPlisio(){
        setLoad(true)
        const res = await plisio()
        console.log(res)
        webApp.openLink(res.payment_url)
        setOpenMenu(false)
        setLoad(false)
    }

    async function clickCryptoCloud(){
        setLoad(true)
        const res = await cryptocloud()
        console.log(res)
        webApp.openLink(res.payment_url)
        setOpenMenu(false)
        setLoad(false)
    }

    async function clickStars(){
        setLoad(true)
        const res = await stars()
        console.log(res)
        webApp.openInvoice(res.payment_url)
        setOpenMenu(false)
        setLoad(false)
    }

    return (
        <>
            <CSSTransition in={openMenu}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={()=>setOpenMenu(false)}></div>
            </CSSTransition>
            <CSSTransition in={openMenu}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.menu}>
                    <div className={classes.closeBlock}>
                        <svg className={classes.close} onClick={()=>setOpenMenu(false)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20ZM12.155 27.845C11.8425 27.5325 11.667 27.1086 11.667 26.6667C11.667 26.2247 11.8425 25.8009 12.155 25.4883L17.6433 20L12.155 14.5117C11.8514 14.1973 11.6834 13.7763 11.6872 13.3393C11.691 12.9023 11.8663 12.4843 12.1753 12.1753C12.4843 11.8663 12.9023 11.691 13.3393 11.6872C13.7763 11.6834 14.1973 11.8514 14.5117 12.155L20 17.6433L25.4883 12.155C25.6421 11.9958 25.826 11.8688 26.0293 11.7815C26.2327 11.6941 26.4514 11.6482 26.6727 11.6462C26.894 11.6443 27.1134 11.6865 27.3183 11.7703C27.5231 11.8541 27.7092 11.9779 27.8657 12.1343C28.0221 12.2908 28.1459 12.4769 28.2297 12.6817C28.3135 12.8866 28.3557 13.106 28.3538 13.3273C28.3518 13.5486 28.3058 13.7673 28.2185 13.9707C28.1312 14.174 28.0042 14.3579 27.845 14.5117L22.3567 20L27.845 25.4883C28.1486 25.8027 28.3166 26.2237 28.3128 26.6607C28.309 27.0977 28.1337 27.5157 27.8247 27.8247C27.5157 28.1337 27.0977 28.309 26.6607 28.3128C26.2237 28.3166 25.8027 28.1486 25.4883 27.845L20 22.3567L14.5117 27.845C14.1991 28.1575 13.7753 28.333 13.3333 28.333C12.8914 28.333 12.4675 28.1575 12.155 27.845Z" fill="#4C4F55"/>
                        </svg>
                    </div>
                    <div className={classes.menuText1}>{texts[lang].addBalance.text1}</div>
                    <div className={classes.blocks}>
                        <div className={classes.block} onClick={clickPlisio}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="48" viewBox="0 0 75 48">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M33.09 35.84v4.036c0 .81.314 1.587.877 2.16a2.97 2.97 0 0 0 2.12.895h11.422c1.692 0 3.158-1.316 3.158-3.042V35.84h4.153v4.04c0 4.066-3.36 7.177-7.311 7.174l-3.902-.002h-3.594c-1.218 0-2.492 0-3.926.002-3.805.003-7.15-3.308-7.145-7.165V35.84h4.148zM37.738.004h1.536l.739.001h4.33L45.87.004 47.51.003C51.459 0 54.82 3.11 54.82 7.177v4.04h-4.153v-4.05c0-1.725-1.466-3.041-3.158-3.041H36.087a2.97 2.97 0 0 0-2.12.895 3.08 3.08 0 0 0-.877 2.16v4.036h-4.148v-4.05C28.937 3.311 32.282 0 36.087.003l1.65.001z" fill="#4574EB"/>
                                    <path d="M41.879 15.083c2.583 0 4.828.786 6.543 2.284l-2.27 3.02c-1.352-1.13-3.115-1.694-4.708-1.694-1.207 0-1.738.49-1.738 1.178 0 2.087 9.102.565 9.102 6.654 0 3.29-2.39 5.475-6.712 5.475-3.26 0-5.53-1.031-7.147-2.603l2.197-3.167a7.217 7.217 0 0 0 5.143 2.16c1.425 0 2.294-.564 2.294-1.35 0-2.234-9.127-.442-9.127-6.752 0-2.75 2.294-5.205 6.423-5.205zm23.42-.024c4.925 0 8.595 3.486 8.595 8.47 0 4.985-3.67 8.471-8.595 8.471-4.926 0-8.62-3.486-8.62-8.47 0-4.985 3.694-8.471 8.62-8.471zm-56.305.27c3.621 0 5.601 2.48 5.601 5.45 0 2.877-1.886 5.308-5.345 5.422l-.256.005H5.082v5.5H.93V15.328h8.065zm11.613 0v12.694h6.47v3.682H16.455V15.33h4.153zm12.483 0v16.376h-4.153V15.33h4.153zm21.73 0v16.376h-4.153V15.33h4.153zm10.479 3.462c-2.68 0-4.395 2.062-4.395 4.738 0 2.652 1.715 4.739 4.395 4.739 2.656 0 4.37-2.087 4.37-4.739 0-2.676-1.714-4.738-4.37-4.738zm-56.86.22H5.081v3.512h3.356c1.111 0 1.932-.639 1.932-1.743 0-1.13-.821-1.768-1.932-1.768z" fill="white"/>
                                </g>
                            </svg>
                        </div>
                        <div className={classes.block} onClick={clickCryptoCloud}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="153" height="26" viewBox="0 0 153 26" fill="none">
                                <g id="LOGO REAL SVG" clipPath="url(#clip0_752_4070)">
                                    <g id="Group 2">
                                        <g id="CryptoCloud">
                                            <path d="M43.4132 7.66506C45.0266 7.66506 46.3669 8.06121 47.4342 8.85349C48.5015 9.64578 49.2213 10.7202 49.5936 12.0767H47.3411C47.0308 11.3084 46.5344 10.7022 45.8519 10.258C45.1817 9.80183 44.3564 9.57375 43.376 9.57375C42.5941 9.57375 41.8929 9.75982 41.2724 10.132C40.6643 10.5041 40.1803 11.0383 39.8204 11.7345C39.4729 12.4188 39.2991 13.2231 39.2991 14.1474C39.2991 15.0597 39.4729 15.864 39.8204 16.5603C40.1803 17.2445 40.6643 17.7727 41.2724 18.1448C41.8929 18.517 42.5941 18.703 43.376 18.703C44.3564 18.703 45.1817 18.481 45.8519 18.0368C46.5344 17.5806 47.0308 16.9744 47.3411 16.2182H49.5936C49.2213 17.5746 48.5015 18.649 47.4342 19.4413C46.3669 20.2216 45.0266 20.6117 43.4132 20.6117C42.1722 20.6117 41.0738 20.3416 40.1182 19.8014C39.1626 19.2492 38.4242 18.487 37.903 17.5146C37.3817 16.5303 37.1211 15.4079 37.1211 14.1474C37.1211 12.8869 37.3817 11.7705 37.903 10.7982C38.4242 9.81384 39.1626 9.04556 40.1182 8.49336C41.0738 7.94116 42.1722 7.66506 43.4132 7.66506Z" fill="white"/>
                                            <path d="M53.2747 12.5268C53.6222 11.8786 54.1186 11.3684 54.764 10.9963C55.4093 10.6121 56.1539 10.4201 56.9979 10.4201V12.6529H56.3649C55.4341 12.6529 54.6833 12.8749 54.1124 13.3191C53.5539 13.7513 53.2747 14.4715 53.2747 15.4799V20.5037H51.1525V10.5821H53.2747V12.5268Z" fill="white"/>
                                            <path d="M60.4401 10.5821L63.5117 17.8747L66.3599 10.5821H68.7241L62.525 25.2214H60.1608L62.3761 20.2696L58.0573 10.5821H60.4401Z" fill="white"/>
                                            <path xmlns="http://www.w3.org/2000/svg" d="M75.4436 10.4561C76.3371 10.4561 77.1314 10.6601 77.8264 11.0683C78.5338 11.4764 79.0861 12.0647 79.4832 12.8329C79.8803 13.6012 80.0789 14.5015 80.0789 15.5339C80.0789 16.5783 79.8803 17.4846 79.4832 18.2529C79.0861 19.0212 78.5338 19.6094 77.8264 20.0175C77.1314 20.4257 76.3371 20.6297 75.4436 20.6297C74.5128 20.6297 73.7185 20.4077 73.0608 19.9635C72.403 19.5193 71.9438 18.9311 71.6832 18.1989V25.2214H69.561V10.5821H71.6832V12.8869C71.9438 12.1547 72.403 11.5665 73.0608 11.1223C73.7185 10.6782 74.5128 10.4561 75.4436 10.4561ZM74.792 12.2567C74.1963 12.2567 73.6627 12.3948 73.1911 12.6709C72.7195 12.935 72.3472 13.3191 72.0742 13.8233C71.8011 14.3155 71.6646 14.8857 71.6646 15.5339C71.6646 16.1941 71.8011 16.7703 72.0742 17.2625C72.3472 17.7547 72.7195 18.1388 73.1911 18.4149C73.6627 18.679 74.1963 18.8111 74.792 18.8111C75.7352 18.8111 76.4923 18.523 77.0632 17.9468C77.634 17.3586 77.9195 16.5543 77.9195 15.5339C77.9195 14.5255 77.634 13.7273 77.0632 13.139C76.4923 12.5508 75.7352 12.2567 74.792 12.2567Z" fill="white"/>
                                            <path d="M86.7211 18.7211V20.5037H85.418C84.3259 20.5037 83.4943 20.2516 82.9235 19.7474C82.365 19.2312 82.0858 18.3789 82.0858 17.1905V12.3287H80.6151V10.5821H82.0858V7.66872H84.2266V10.5821H86.7025V12.3287H84.2266V17.2265C84.2266 17.7907 84.3321 18.1809 84.543 18.3969C84.7664 18.613 85.1511 18.7211 85.6972 18.7211H86.7211Z" fill="white"/>
                                            <path d="M92.8417 10.4561C93.8345 10.4561 94.7281 10.6601 95.5224 11.0683C96.3166 11.4764 96.9372 12.0647 97.3839 12.8329C97.8307 13.6012 98.0541 14.5015 98.0541 15.5339C98.0541 16.5783 97.8307 17.4846 97.3839 18.2529C96.9372 19.0212 96.3166 19.6094 95.5224 20.0175C94.7281 20.4257 93.8345 20.6297 92.8417 20.6297C91.8489 20.6297 90.9553 20.4257 90.161 20.0175C89.3792 19.6094 88.7587 19.0212 88.2995 18.2529C87.8527 17.4726 87.6293 16.5663 87.6293 15.5339C87.6293 14.5015 87.8527 13.6012 88.2995 12.8329C88.7587 12.0647 89.3792 11.4764 90.161 11.0683C90.9553 10.6601 91.8489 10.4561 92.8417 10.4561ZM92.8417 12.2387C92.2956 12.2387 91.793 12.3588 91.3338 12.5988C90.8746 12.8389 90.5023 13.2111 90.2169 13.7152C89.9439 14.2074 89.8073 14.8136 89.8073 15.5339C89.8073 16.2662 89.9439 16.8784 90.2169 17.3706C90.5023 17.8627 90.8746 18.2289 91.3338 18.469C91.793 18.709 92.2956 18.8291 92.8417 18.8291C93.3878 18.8291 93.8904 18.709 94.3496 18.469C94.8088 18.2289 95.1749 17.8627 95.4479 17.3706C95.7333 16.8784 95.8761 16.2662 95.8761 15.5339C95.8761 14.8136 95.7333 14.2074 95.4479 13.7152C95.1749 13.2111 94.8088 12.8389 94.3496 12.5988C93.8904 12.3588 93.3878 12.2387 92.8417 12.2387Z" fill="white"/>
                                            <path d="M105.4 7.66506C107.013 7.66506 108.354 8.06121 109.421 8.85349C110.488 9.64578 111.208 10.7202 111.58 12.0767H109.328C109.018 11.3084 108.521 10.7022 107.839 10.258C107.168 9.80183 106.343 9.57375 105.363 9.57375C104.581 9.57375 103.88 9.75982 103.259 10.132C102.651 10.5041 102.167 11.0383 101.807 11.7345C101.46 12.4188 101.286 13.2231 101.286 14.1474C101.286 15.0597 101.46 15.864 101.807 16.5603C102.167 17.2445 102.651 17.7727 103.259 18.1448C103.88 18.517 104.581 18.703 105.363 18.703C106.343 18.703 107.168 18.481 107.839 18.0368C108.521 17.5806 109.018 16.9744 109.328 16.2182H111.58C111.208 17.5746 110.488 18.649 109.421 19.4413C108.354 20.2216 107.013 20.6117 105.4 20.6117C104.159 20.6117 103.061 20.3416 102.105 19.8014C101.149 19.2492 100.411 18.487 99.8896 17.5146C99.3684 16.5303 99.1078 15.4079 99.1078 14.1474C99.1078 12.8869 99.3684 11.7705 99.8896 10.7982C100.411 9.81384 101.149 9.04556 102.105 8.49336C103.061 7.94116 104.159 7.66506 105.4 7.66506Z" fill="white"/>
                                            <path d="M115.261 7.69753V20.5037H113.139V7.69753H115.261Z" fill="white"/>
                                            <path d="M121.962 10.4561C122.955 10.4561 123.849 10.6601 124.643 11.0683C125.437 11.4764 126.058 12.0647 126.504 12.8329C126.951 13.6012 127.175 14.5015 127.175 15.5339C127.175 16.5783 126.951 17.4846 126.504 18.2529C126.058 19.0212 125.437 19.6094 124.643 20.0175C123.849 20.4257 122.955 20.6297 121.962 20.6297C120.969 20.6297 120.076 20.4257 119.282 20.0175C118.5 19.6094 117.879 19.0212 117.42 18.2529C116.973 17.4726 116.75 16.5663 116.75 15.5339C116.75 14.5015 116.973 13.6012 117.42 12.8329C117.879 12.0647 118.5 11.4764 119.282 11.0683C120.076 10.6601 120.969 10.4561 121.962 10.4561ZM121.962 12.2387C121.416 12.2387 120.913 12.3588 120.454 12.5988C119.995 12.8389 119.623 13.2111 119.337 13.7152C119.064 14.2074 118.928 14.8136 118.928 15.5339C118.928 16.2662 119.064 16.8784 119.337 17.3706C119.623 17.8627 119.995 18.2289 120.454 18.469C120.913 18.709 121.416 18.8291 121.962 18.8291C122.508 18.8291 123.011 18.709 123.47 18.469C123.929 18.2289 124.295 17.8627 124.568 17.3706C124.854 16.8784 124.997 16.2662 124.997 15.5339C124.997 14.8136 124.854 14.2074 124.568 13.7152C124.295 13.2111 123.929 12.8389 123.47 12.5988C123.011 12.3588 122.508 12.2387 121.962 12.2387Z" fill="white"/>
                                            <path d="M138.113 10.5821V20.5037H135.972V18.3429C135.712 19.0752 135.259 19.6454 134.613 20.0535C133.968 20.4497 133.217 20.6477 132.361 20.6477C131.17 20.6477 130.214 20.2756 129.494 19.5313C128.774 18.7871 128.414 17.7247 128.414 16.3442V10.5821H130.537V16.1281C130.537 17.0284 130.772 17.7247 131.244 18.2169C131.716 18.697 132.361 18.9371 133.18 18.9371C134.024 18.9371 134.7 18.673 135.209 18.1448C135.718 17.6167 135.972 16.8484 135.972 15.84V10.5821H138.113Z" fill="white"/>
                                            <path d="M144.21 10.4561C145.141 10.4561 145.935 10.6782 146.593 11.1223C147.251 11.5665 147.71 12.1547 147.971 12.8869V7.69752H150.112V20.5037H147.971V18.1989C147.71 18.9311 147.251 19.5193 146.593 19.9635C145.935 20.4077 145.141 20.6297 144.21 20.6297C143.317 20.6297 142.516 20.4257 141.809 20.0175C141.114 19.6094 140.568 19.0212 140.171 18.2529C139.774 17.4846 139.575 16.5783 139.575 15.5339C139.575 14.5015 139.774 13.6012 140.171 12.8329C140.568 12.0647 141.114 11.4764 141.809 11.0683C142.516 10.6601 143.317 10.4561 144.21 10.4561ZM144.862 12.2567C143.919 12.2567 143.162 12.5508 142.591 13.139C142.032 13.7152 141.753 14.5135 141.753 15.5339C141.753 16.5543 142.032 17.3586 142.591 17.9468C143.162 18.523 143.919 18.8111 144.862 18.8111C145.458 18.8111 145.991 18.679 146.463 18.4149C146.934 18.1388 147.307 17.7547 147.58 17.2625C147.853 16.7703 147.989 16.1941 147.989 15.5339C147.989 14.8857 147.853 14.3155 147.58 13.8233C147.307 13.3191 146.934 12.935 146.463 12.6709C145.991 12.3948 145.458 12.2567 144.862 12.2567Z" fill="white"/>
                                        </g>
                                        <g id="ICON">
                                            <path id="Subtract" d="M20.5624 10.9024C20.5624 14.0207 17.9639 16.5487 14.7585 16.5487C12.6665 16.5487 10.8331 15.472 9.81174 13.8572C9.54235 15.0155 8.50868 15.8967 7.24575 15.9868C8.8992 18.2947 11.6475 19.8048 14.7585 19.8048C19.8124 19.8048 23.9095 15.8191 23.9095 10.9024C23.9095 5.98574 19.8124 2 14.7585 2C9.70959 2 5.61571 5.97767 5.60742 10.8875C6.02641 10.6546 6.51285 10.5214 7.0317 10.5214C7.77802 10.5214 8.4573 10.7971 8.9652 11.2483C8.95809 11.1339 8.95448 11.0185 8.95448 10.9024C8.95448 7.78405 11.553 5.25613 14.7585 5.25613C17.9639 5.25613 20.5624 7.78405 20.5624 10.9024Z" fill="url(#paint0_linear_752_4070)"/>
                                            <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M26.3303 20.1515C29.6374 20.1329 32.3125 17.5191 32.3125 14.2975C32.3125 11.0643 29.6183 8.44336 26.2949 8.44336C23.5522 8.44336 20.9538 10.6949 20.2773 12.6348C19.6007 14.5746 18.2269 15.6507 16.7521 16.2026C16.7549 16.2107 16.7578 16.2188 16.7607 16.2269C15.262 16.6806 14.3975 16.644 12.8767 16.2032H7.03125V20.1521H26.3303V20.1515ZM29.0364 14.2971C29.0364 15.7702 27.8089 16.9644 26.2947 16.9644C24.7804 16.9644 23.5529 15.7702 23.5529 14.2971C23.5529 12.8241 24.7804 11.6299 26.2947 11.6299C27.8089 11.6299 29.0364 12.8241 29.0364 14.2971Z" fill="url(#paint1_linear_752_4070)"/>
                                            <path id="Vector" d="M7.05631 6.40625C5.6607 6.40625 4.29644 6.80934 3.13603 7.56456C1.97563 8.31977 1.07121 9.39318 0.537132 10.6491C0.00305724 11.9049 -0.136681 13.2869 0.135588 14.6201C0.407857 15.9533 1.0799 17.178 2.06675 18.1392C3.05359 19.1004 4.3109 19.755 5.67969 20.0202C7.04848 20.2854 8.46727 20.1492 9.75664 19.629C11.046 19.1088 12.1481 18.2279 12.9234 17.0977C13.6988 15.9674 14.1126 14.6386 14.1126 13.2792C14.1126 12.3767 13.9301 11.4829 13.5755 10.6491C13.2209 9.81519 12.7011 9.05752 12.0459 8.4193C11.3906 7.78108 10.6127 7.27482 9.75664 6.92942C8.90053 6.58402 7.98295 6.40625 7.05631 6.40625ZM7.05631 15.9402C6.51598 15.9402 5.98779 15.7841 5.53852 15.4918C5.08925 15.1994 4.73909 14.7838 4.53232 14.2975C4.32554 13.8113 4.27144 13.2763 4.37685 12.7601C4.48227 12.2439 4.74246 11.7698 5.12453 11.3976C5.5066 11.0255 5.99338 10.7721 6.52333 10.6694C7.05328 10.5667 7.60258 10.6194 8.10178 10.8208C8.60097 11.0222 9.02765 11.3633 9.32784 11.8009C9.62803 12.2385 9.78825 12.7529 9.78825 13.2792C9.78871 13.629 9.71838 13.9753 9.58129 14.2986C9.4442 14.6218 9.24304 14.9155 8.98931 15.163C8.73558 15.4104 8.43425 15.6068 8.10257 15.7407C7.77089 15.8746 7.41536 15.9436 7.05631 15.9436V15.9402Z" fill="#6763F7"/>
                                            <path id="Vector_2" d="M26.1617 4.90505C26.9639 4.90505 27.6143 4.27157 27.6143 3.49012C27.6143 2.70868 26.9639 2.0752 26.1617 2.0752C25.3594 2.0752 24.709 2.70868 24.709 3.49012C24.709 4.27157 25.3594 4.90505 26.1617 4.90505Z" fill="#FFAD4D"/>
                                        </g>
                                    </g>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_752_4070" x1="11.8031" y1="-0.528697" x2="22.8377" y2="11.2408" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#6763F7"/>
                                        <stop offset="1" stopColor="#4A40C6"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_752_4070" x1="19.5294" y1="12.6003" x2="19.5294" y2="20.1521" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#6864F9"/>
                                        <stop offset="1" stopColor="#493FC5"/>
                                    </linearGradient>
                                    <clipPath id="clip0_752_4070">
                                        <rect width="153" height="25" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className={classes.block2} onClick={clickStars}>
                            <svg className="lbK6aMGA" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <defs>
                                    <linearGradient id="svg-m1s9zovgj5s3pwi7wde-fill" x1="0.434893" y1="22.5796"
                                                    x2="34.2364" y2="-15.5089" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FDEB32"></stop>
                                        <stop offset="0.439058" stopColor="#FEBD04"></stop>
                                        <stop offset="1" stopColor="#D75902"></stop>
                                    </linearGradient>
                                    <linearGradient id="svg-m1s9zovgj5s3pwi7wde-stroke1" x1="22.5" y1="2.5" x2="8"
                                                    y2="12.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#DB5A00"></stop>
                                        <stop offset="1" stopColor="#FF9145"></stop>
                                    </linearGradient>
                                    <linearGradient id="svg-m1s9zovgj5s3pwi7wde-stroke2" x1="24.5" y1="2" x2="11"
                                                    y2="10.2302" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white" stopOpacity="0"></stop>
                                        <stop offset="0.395833" stopColor="white" stopOpacity="0.85"></stop>
                                        <stop offset="0.520833" stopColor="white"></stop>
                                        <stop offset="0.645833" stopColor="white" stopOpacity="0.85"></stop>
                                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                                    </linearGradient>
                                </defs>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M10.5197 16.2049L6.46899 18.6864C6.04779 18.9444 5.49716 18.8121 5.23913 18.3909C5.11311 18.1852 5.07554 17.9373 5.13494 17.7035L5.762 15.2354C5.98835 14.3444 6.59803 13.5997 7.42671 13.2018L11.8459 11.0801C12.0519 10.9812 12.1387 10.734 12.0398 10.528C11.9597 10.3611 11.7786 10.2677 11.5962 10.2993L6.67709 11.1509C5.67715 11.324 4.65172 11.0479 3.87392 10.3961L2.31994 9.09382C1.94135 8.77655 1.89164 8.21245 2.20891 7.83386C2.36321 7.64972 2.58511 7.53541 2.82462 7.51667L7.5725 7.1451C7.90793 7.11885 8.20025 6.90658 8.32901 6.59574L10.1607 2.17427C10.3497 1.71792 10.8729 1.50123 11.3292 1.69028C11.5484 1.78105 11.7225 1.95514 11.8132 2.17427L13.6449 6.59574C13.7736 6.90658 14.066 7.11885 14.4014 7.1451L19.1754 7.51871C19.6678 7.55725 20.0358 7.9877 19.9972 8.48015C19.9787 8.71704 19.8666 8.93682 19.6858 9.09098L16.0449 12.1949C15.7886 12.4134 15.6768 12.7574 15.7556 13.0849L16.8749 17.7348C16.9905 18.215 16.6949 18.698 16.2147 18.8137C15.9839 18.8692 15.7406 18.8307 15.5382 18.7068L11.4541 16.2049C11.1674 16.0292 10.8064 16.0292 10.5197 16.2049Z"
                                      fill="url(#svg-m1s9zovgj5s3pwi7wde-fill)"
                                      stroke="url(#svg-m1s9zovgj5s3pwi7wde-stroke1)"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M10.5197 16.2049L6.46899 18.6864C6.04779 18.9444 5.49716 18.8121 5.23913 18.3909C5.11311 18.1852 5.07554 17.9373 5.13494 17.7035L5.762 15.2354C5.98835 14.3444 6.59803 13.5997 7.42671 13.2018L11.8459 11.0801C12.0519 10.9812 12.1387 10.734 12.0398 10.528C11.9597 10.3611 11.7786 10.2677 11.5962 10.2993L6.67709 11.1509C5.67715 11.324 4.65172 11.0479 3.87392 10.3961L2.31994 9.09382C1.94135 8.77655 1.89164 8.21245 2.20891 7.83386C2.36321 7.64972 2.58511 7.53541 2.82462 7.51667L7.5725 7.1451C7.90793 7.11885 8.20025 6.90658 8.32901 6.59574L10.1607 2.17427C10.3497 1.71792 10.8729 1.50123 11.3292 1.69028C11.5484 1.78105 11.7225 1.95514 11.8132 2.17427L13.6449 6.59574C13.7736 6.90658 14.066 7.11885 14.4014 7.1451L19.1754 7.51871C19.6678 7.55725 20.0358 7.9877 19.9972 8.48015C19.9787 8.71704 19.8666 8.93682 19.6858 9.09098L16.0449 12.1949C15.7886 12.4134 15.6768 12.7574 15.7556 13.0849L16.8749 17.7348C16.9905 18.215 16.6949 18.698 16.2147 18.8137C15.9839 18.8692 15.7406 18.8307 15.5382 18.7068L11.4541 16.2049C11.1674 16.0292 10.8064 16.0292 10.5197 16.2049Z"
                                      stroke="url(#svg-m1s9zovgj5s3pwi7wde-stroke2)" strokeWidth="2"
                                      style={{mixBlendMode: 'soft-light'}}></path>
                            </svg>
                            Telegram Stars
                        </div>
                </div>
            </CSSTransition>
            <Loading isOpen={load}/>
        </>
    );
};

export default AddBalance;