import Main from "./components/pages/main/main";
import {useEffect} from "react";

function App() {

    useEffect(()=>{
        console.log("Version: 0.47")
    },[])
  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
