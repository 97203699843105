import React, {useCallback, useEffect, useState} from 'react';
import classes from "./main.module.css";
import StartBlock from "./startBlock/startBlock";
import Header from "./header/header";
import Bonuses from "./bonuses/bonuses";
import background2 from '../../../images/background2.png'
import Balance from "./balance/balance";
import Button from "./button/button";
import {texts} from "../../../translate";
import Menu from "./menu/menu";
import Referrals from "./referrals/referrals";
import Roadmap from "./roadmap/roadmap";
import Profit from "./profit/profit";
import {getMe, register} from "../../../utils/api";
import {useTelegram} from "../../../hooks/useTelegram";
import Notificate from "./notificate/notificate";
import Withdraw from "./withdraw/withdraw";
import AddBalance from "./addBalance/addBalance";

const Main = () => {
    const {webApp, langT} = useTelegram()
    const [startBlock, setStartBlock] = useState(true)
    const [openSettings, setOpenSettings] = useState(false)
    const [openInstruction, setOpenInstruction] = useState(false)
    const [balance, setBalance] = useState(0)
    const [active, setActive] = useState(1)
    const [lang, setLang] = useState(langT)
    const [data, setData] = useState()
    const [cryptoAddress, setCryptoAddress] = useState('')
    const [automateWithdrawal, setAutomateWithdrawal] = useState(false)
    const [timer, setTimer] = useState(0)
    const [notification, setNotification] = useState(0)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const [openAddBalance, setOpenAddBalance] = useState(false)

    const onBackClick = useCallback(() => {
        setTimer(Date.now())
    },[timer])

    useEffect(()=>{
        webApp.BackButton.hide()
        setOpenSettings(false)
        setOpenInstruction(false)
        getMeF()
    },[timer])

    useEffect(()=>{
        if(openSettings){
            webApp.BackButton.show()
        }
    },[openSettings])

    useEffect(()=>{
        if(openInstruction){
            webApp.BackButton.show()
        }
    },[openInstruction])

    useEffect(() => {
        webApp.ready()
        //webApp.enableClosingConfirmation()
        webApp.expand()
        webApp.BackButton.onClick(onBackClick)
        return () => {
            webApp.BackButton.offClick(onBackClick)
        };
    }, [webApp])

    const getMeF = async ()=>{
        const res = await getMe()
        console.log(res)
        if(!res.error){
            setData(res)
            setBalance(res?.user?.balance)
            setCryptoAddress(res?.user?.crypto_address)
            setAutomateWithdrawal(res?.user?.automate_withdrawal)
        }
    }

    const registerF = async ()=>{
        const hash = window.location.hash.slice(1);
        const params = new URLSearchParams(hash);
        const tgWebAppData = new URLSearchParams(params.get('tgWebAppData'));
        const inviter_id = tgWebAppData.get('start_param');
        const res = await register(inviter_id)
        console.log(res)
        await getMeF()
    }

    useEffect(()=>{
        registerF()
        setTimeout(()=>{setStartBlock(false)},5000)
        setInterval(getMeF, 5000)
    },[])

    useEffect(()=>{
        getMeF()
        if(active === 2 || active === 3){
            if(!data?.user.is_active){
                setActive(1)
                setNotification(Date.now());
            }
        }
    },[active])

    useEffect(()=>{
        getMeF()
    },[openAddBalance])

    async function addBalance(){
        if(data.user.is_active){
            setOpenWithdraw(true)
        }else{
            await getMeF()
            if(!data.user.is_active) {
                setOpenAddBalance(true)
            }

            /*const res = await topUpBalance()
            console.log(res)
            setBalance(100)
            getMeF()*/
        }
    }

    return (
        <>
            {startBlock && <StartBlock lang={lang} setStartBlock={setStartBlock}/>}
            <div style={{opacity:startBlock?0:1}} className={classes._}>
                <Header lang={lang}
                        setLang={setLang}
                        openSettings={openSettings}
                        setOpenSettings={setOpenSettings}
                        openInstruction={openInstruction}
                        setOpenInstruction={setOpenInstruction}
                        data={data}
                        cryptoAddress={cryptoAddress}
                        setCryptoAddress={setCryptoAddress}
                        automateWithdrawal={automateWithdrawal}
                        setAutomateWithdrawal={setAutomateWithdrawal}
                />
                {active === 1 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Button click={addBalance} active={true}>{data?.user.is_active?texts[lang].button.text2:texts[lang].button.text1}</Button>
                        <AddBalance lang={lang} openMenu={openAddBalance} setOpenMenu={setOpenAddBalance}/>
                        <div style={{height:'78px'}}></div>
                    </>
                }
                {active === 2 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Referrals lang={lang}/>
                    </>
                }
                {active === 3 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Profit lang={lang} setBalance={setBalance} balance={balance}/>
                    </>
                }
                {active === 4 &&
                    <Roadmap lang={lang} setActive={setActive} data={data}/>
                }
                <Menu lang={lang} active={active} setActive={setActive}/>
            </div>
            <Withdraw lang={lang}
                      data={data}
                      openWithdraw={openWithdraw}
                      setOpenWithdraw={setOpenWithdraw}
                      cryptoAddress={cryptoAddress}
                      setTimer={setTimer}/>
            <Notificate text={texts[lang].notificate.text2} open={notification}/>
        </>
    );
};

export default Main;