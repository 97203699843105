import React from 'react';
import classes from "./instruction.module.css";
import logo from '../../../../images/instructionLogo.jpg'

const Instruction = ({lang}) => {

    return (
        <div className={classes._}>
            <img alt={''} src={logo} className={classes.logo}/>
            <div className={classes.block}>
                {lang === 'ru' ? <div className={classes.text} dir="auto">
                    <strong data-entity-type="MessageEntityBold">Инструкция по использованию приложения Refetron<br/>
                        <br/>Общее описание:<br/>
                        </strong>
                    Refetron — это приложение с реферальными возможностями, позволяющее вам
                    увеличивать капитал.<br/>
                    <strong data-entity-type="MessageEntityBold"><br/>Шаг 1: Приобретение коинов</strong>- Для начала вам необходимо приобрести 100 коинов приложения Refetron. Так же
                        эта приобретение включает бонус, годовой абонемент на использование органайзера с ИИ (По
                        готовности данного органайзера, стоимость годового абонемента для остальных пользователей будет
                        равняться 50$). <br/><strong data-entity-type="MessageEntityBold"><br/>Шаг 2: Как заработать
                            1,000,000 коинов, начиная со 100:<br/></strong><br/>1.<strong
                            data-entity-type="MessageEntityBold">&nbsp;Долгосрочные
                            дивиденды:<br/></strong>&nbsp;&nbsp;&nbsp;- Просто ожидайте начисления дивидендов.
                            Приобретенные вами 100 коинов приносят 24% годовых в виде бонусных коинов.<br/><br/>2.<strong
                                data-entity-type="MessageEntityBold">&nbsp;Реферальные
                                бонусы:<br/></strong>&nbsp;&nbsp;&nbsp;- Пригласите 10 друзей в приложение и получите по
                                10 коинов с каждого!<br/>&nbsp;&nbsp;&nbsp;- Также получайте по 5 коинов с каждого
                                    реферала последующих уровней до пятого уровня включительно.<br/><br/>3.<strong
                                        data-entity-type="MessageEntityBold">&nbsp;Увеличение реферальных
                                        вознаграждений:<br/></strong>&nbsp;&nbsp;&nbsp;- В разделе «Прокачать прибыль» вы
                                        можете увеличить бонусы. Каждая подписка на каналы увеличивает постоянное общие
                                        вознаграждение конкретного уровня на 1 коин (увеличить можно до 10 коинов).
                                        Обратите внимание: каждая подписка стоит 5 коинов, снимается с общего
                                        баланса.<br/><strong data-entity-type="MessageEntityBold"><br/>Шаг 3: Вывод
                                            заработанных коинов<br/></strong>- Заработанные коины можно выводить на
                                            указанный вами крипта кошелек в USDT/BSC. <br/>- Минимальная сумма для вывода
                                                — 1000 USDT/BSC.<br/>- Курс вывода коина составляет 0.02
                                                    USDT/BSC.<br/><strong data-entity-type="MessageEntityBold"><br/>Шаг 4:
                                                        Как увеличить курс коина<br/></strong>- Дождитесь запуска
                                                        крипто-кошелька и обменяйте заработанные коины 1:1 на токен
                                                        кошелька, курс которого составляет 0.03 USDT/BSC.<br/><strong
                                                            data-entity-type="MessageEntityBold"><br/>Шаг 5: Как
                                                            увеличить заработанные средства<br/></strong>1.<strong
                                                            data-entity-type="MessageEntityBold">&nbsp;Стейкинг:<br/></strong>&nbsp;&nbsp;&nbsp;-
                                                            После запуска крипто-биржи вы сможете положить заработанные
                                                            средства или их часть на стейкинг и получать ежемесячно
                                                            проценты от общего дохода.<br/><br/>2.<strong
                                                                data-entity-type="MessageEntityBold">&nbsp;Инвестиции в
                                                                IEO стартапы:<br/></strong>&nbsp;&nbsp;&nbsp;- Также вы
                                                                можете покупать акции IEO стартапов и зарабатывать
                                                                дивиденды, начиная с 3x и более (подробности будут
                                                                доступны при старте крипто-биржи).<br/><strong
                                                                    data-entity-type="MessageEntityBold"><br/>Общая
                                                                    информация по приобретению 100
                                                                    коинов<br/></strong><br/>Коины можно приобрести за
                                                                    криптовалюту по цене, эквивалентной 15 USDT.<br/><br/>Также
                                                                        вы можете получить 100 коинов за 1000
                                                                        телеграм-звезд.
                </div> :
                <div className={classes.text} dir="auto"><strong
                    data-entity-type="MessageEntityBold">Instructions for Using the Refetron App<br/><br/>General
                    Description:<br/></strong>Refetron is an app with referral capabilities that allows you to increase
                    your capital.<br/><strong data-entity-type="MessageEntityBold"><br/>Step 1: Acquiring
                        Coins<br/></strong>- First, you need to purchase 100 coins of the Refetron app. This purchase
                        also includes a bonus: an annual subscription for using the AI organizer (Once the organizer is
                        ready, the annual subscription cost for other users will be $50).<br/><strong
                            data-entity-type="MessageEntityBold"><br/>Step 2: How to Earn 1,000,000 Coins Starting from
                            100:<br/></strong><br/>1.<strong data-entity-type="MessageEntityBold">&nbsp;Long-term
                            Dividends:<br/></strong>&nbsp;&nbsp;&nbsp;- Simply wait for the dividends to be credited. The
                            100 coins you purchased yield 24% annually in the form of bonus coins.<br/><br/>2.<strong
                                data-entity-type="MessageEntityBold">&nbsp;Referral
                                Bonuses:<br/></strong>&nbsp;&nbsp;&nbsp;- Invite 10 friends to the app and earn 10 coins
                                from each!<br/>&nbsp;&nbsp;&nbsp;- Additionally, earn 5 coins from each referral at
                                    subsequent levels up to the fifth level inclusive.<br/><br/>3.<strong
                                        data-entity-type="MessageEntityBold">&nbsp;Increasing Referral
                                        Rewards:<br/></strong>&nbsp;&nbsp;&nbsp;- In the "Boost Profits" section, you can
                                        increase bonuses. Each subscription to channels increases the total rewards for
                                        that specific level by 1 coin (up to 10 coins). Note: each subscription costs 5
                                        coins, deducted from your total balance.<br/><strong
                                            data-entity-type="MessageEntityBold"><br/>Step 3: Withdrawing Earned
                                            Coins<br/></strong>- Earned coins can be withdrawn to your specified crypto
                                            wallet in USDT/BSC.<br/>- The minimum withdrawal amount is 1000 USDT/BSC.<br/>-
                                                The coin withdrawal rate is 0.02 USDT/BSC.<br/><strong
                                                    data-entity-type="MessageEntityBold"><br/>Step 4: How to Increase the
                                                    Coin Rate<br/></strong>- Wait for the launch of the crypto wallet and
                                                    exchange your earned coins 1:1 for the wallet token, which has a
                                                    rate of 0.03 USDT/BSC.<br/><strong
                                                        data-entity-type="MessageEntityBold"><br/>Step 5: How to Increase
                                                        Your Earnings<br/></strong>1.<strong
                                                        data-entity-type="MessageEntityBold">&nbsp;Staking:<br/></strong>&nbsp;&nbsp;&nbsp;-
                                                        After the launch of the crypto exchange, you can deposit your
                                                        earned funds or part of them in staking and receive monthly
                                                        interest from the total income.<br/><br/>2.<strong
                                                            data-entity-type="MessageEntityBold">&nbsp;Investing in IEO
                                                            Startups:<br/></strong>&nbsp;&nbsp;&nbsp;- You can also buy
                                                            shares of IEO startups and earn dividends starting from 3x
                                                            and more (details will be available at the launch of the
                                                            crypto exchange).<br/><strong
                                                                data-entity-type="MessageEntityBold"><br/>General
                                                                Information on Acquiring 100 Coins<br/></strong><br/>Coins
                                                                can be purchased for cryptocurrency at a price
                                                                equivalent to 15 USDT.<br/><br/>You can also get 100 coins
                                                                    for 1000 Telegram stars.
                </div>}
            </div>
        </div>
    );
};

export default Instruction;