import React, {useEffect, useState} from 'react';
import classes from "./withdraw.module.css";
import {texts} from "../../../../translate";
import {CSSTransition} from "react-transition-group";
import Notificate from "../notificate/notificate";
import {userWithdrawalRequests, withdrawalRequest} from "../../../../utils/api";
import coin from '../../../../images/coin.png'

const Withdraw = ({data, lang, openWithdraw, setOpenWithdraw, cryptoAddress, setTimer}) => {
    const [checked, setChecked] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)
    const [notification, setNotification] = useState(0);
    const [notification2, setNotification2] = useState(0);
    const [value, setValue] = useState(data?.user.balance * data?.withdrawal_setting.exchange_rate)
    const [history, setHistory] = useState()

    useEffect(()=>{
        setValue(data?.user.balance * data?.withdrawal_setting.exchange_rate)
    },[data])

    useEffect(()=>{
        if(value <= data?.user.balance * data?.withdrawal_setting.exchange_rate && value >= data?.withdrawal_setting.min_amount){
            setChecked(true)
        }else{
            setChecked(false)
        }
    },[value])

    useEffect(()=>{
        const userWithdrawalRequestsT = async ()=>{
            const res = await userWithdrawalRequests()
            console.log(res)
            setHistory(res)
        }
        if(data && data.user)
            userWithdrawalRequestsT()
    },[openInfo])

    async function startWithdraw(){
        if(cryptoAddress === '' || !cryptoAddress){
            setNotification(Date.now());
        }else{
            if(checked){
                const res = await withdrawalRequest(value / data?.withdrawal_setting.exchange_rate)
                console.log(res)
                if(res.status === 'REQUEST'){
                    setTimer(Date.now())
                    setOpenWithdraw(false)
                }
            }else{
                if(value < data?.withdrawal_setting.min_amount){
                    setNotification2(Date.now());
                }
            }
        }
    }

    return (
        <>
            <CSSTransition in={openWithdraw}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={()=>setOpenWithdraw(false)}></div>
            </CSSTransition>
            <CSSTransition in={openWithdraw}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.menu}>
                    <div className={classes.closeBlock}>
                        <svg className={classes.close} onClick={()=>setOpenWithdraw(false)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20ZM12.155 27.845C11.8425 27.5325 11.667 27.1086 11.667 26.6667C11.667 26.2247 11.8425 25.8009 12.155 25.4883L17.6433 20L12.155 14.5117C11.8514 14.1973 11.6834 13.7763 11.6872 13.3393C11.691 12.9023 11.8663 12.4843 12.1753 12.1753C12.4843 11.8663 12.9023 11.691 13.3393 11.6872C13.7763 11.6834 14.1973 11.8514 14.5117 12.155L20 17.6433L25.4883 12.155C25.6421 11.9958 25.826 11.8688 26.0293 11.7815C26.2327 11.6941 26.4514 11.6482 26.6727 11.6462C26.894 11.6443 27.1134 11.6865 27.3183 11.7703C27.5231 11.8541 27.7092 11.9779 27.8657 12.1343C28.0221 12.2908 28.1459 12.4769 28.2297 12.6817C28.3135 12.8866 28.3557 13.106 28.3538 13.3273C28.3518 13.5486 28.3058 13.7673 28.2185 13.9707C28.1312 14.174 28.0042 14.3579 27.845 14.5117L22.3567 20L27.845 25.4883C28.1486 25.8027 28.3166 26.2237 28.3128 26.6607C28.309 27.0977 28.1337 27.5157 27.8247 27.8247C27.5157 28.1337 27.0977 28.309 26.6607 28.3128C26.2237 28.3166 25.8027 28.1486 25.4883 27.845L20 22.3567L14.5117 27.845C14.1991 28.1575 13.7753 28.333 13.3333 28.333C12.8914 28.333 12.4675 28.1575 12.155 27.845Z" fill="#4C4F55"/>
                        </svg>
                    </div>
                    <div className={classes.menuText1}>{texts[lang].withdraw.text1}</div>
                    <div className={classes.blockText2}>
                        {texts[lang].balance.text2}: {data?.user.balance}
                        <img alt={''} src={coin} className={classes.coin}/>
                    </div>
                    <div className={classes.blockText2}>{texts[lang].withdraw.text9}: {data?.withdrawal_setting.exchange_rate} USDT</div>
                    <div className={classes.block}>
                        <div className={classes.blockText}>{texts[lang].withdraw.text10}: </div>
                        <input className={classes.input}
                               value={value}
                               type={'number'}
                               pattern="\\d*"
                               inputMode="decimal"
                               onChange={(e)=> {
                                   if(e.target.value.length === 0){
                                       setValue('')
                                   }else{
                                       if (e.target.value <= data?.user.balance * data?.withdrawal_setting.exchange_rate) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if(e.target.value === '.' || e.target.value === ','){
                                               setValue('0,')
                                           }else{
                                               if (alf.indexOf(last) !== -1) {
                                                   if(((last === '.' || last === ',') &&
                                                       (e.target.value.slice(0,-1).indexOf('.') !== -1 || e.target.value.slice(0,-1).indexOf(',') !== -1)) ||
                                                       ((e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1) &&
                                                           (e.target.value.length - Math.max(e.target.value.indexOf('.'),e.target.value.indexOf(',')))) > 3
                                                   ){

                                                   }else{
                                                       setValue(e.target.value)
                                                   }
                                               }
                                           }
                                       }else{
                                           setValue(data?.user.balance * data?.withdrawal_setting.exchange_rate)
                                       }
                                   }

                               }}/>
                        <div className={classes.blockText}>USDT/BSC</div>
                    </div>
                    <div className={classes.text2}>{texts[lang].withdraw.text2} {data?.withdrawal_setting.min_amount} USDT</div>
                    <div className={classes.menuButtons}>
                        <div className={classes.button1} onClick={startWithdraw}>{texts[lang].button.text2}</div>
                        <div className={classes.button2} onClick={()=>setOpenInfo(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.9" d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C12.9993 7.71733 12.9033 7.48 12.712 7.288C12.5207 7.096 12.2833 7 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C10.9993 8.28267 11.0953 8.52033 11.288 8.713C11.4807 8.90567 11.718 9.00133 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22Z" fill="#15191F"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={openInfo}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon} style={{zIndex:12}} onClick={()=>setOpenInfo(false)}></div>
            </CSSTransition>
            <CSSTransition in={openInfo}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.menu}>
                    <div className={classes.closeBlock}>
                        <svg className={classes.close} onClick={()=>setOpenInfo(false)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20ZM12.155 27.845C11.8425 27.5325 11.667 27.1086 11.667 26.6667C11.667 26.2247 11.8425 25.8009 12.155 25.4883L17.6433 20L12.155 14.5117C11.8514 14.1973 11.6834 13.7763 11.6872 13.3393C11.691 12.9023 11.8663 12.4843 12.1753 12.1753C12.4843 11.8663 12.9023 11.691 13.3393 11.6872C13.7763 11.6834 14.1973 11.8514 14.5117 12.155L20 17.6433L25.4883 12.155C25.6421 11.9958 25.826 11.8688 26.0293 11.7815C26.2327 11.6941 26.4514 11.6482 26.6727 11.6462C26.894 11.6443 27.1134 11.6865 27.3183 11.7703C27.5231 11.8541 27.7092 11.9779 27.8657 12.1343C28.0221 12.2908 28.1459 12.4769 28.2297 12.6817C28.3135 12.8866 28.3557 13.106 28.3538 13.3273C28.3518 13.5486 28.3058 13.7673 28.2185 13.9707C28.1312 14.174 28.0042 14.3579 27.845 14.5117L22.3567 20L27.845 25.4883C28.1486 25.8027 28.3166 26.2237 28.3128 26.6607C28.309 27.0977 28.1337 27.5157 27.8247 27.8247C27.5157 28.1337 27.0977 28.309 26.6607 28.3128C26.2237 28.3166 25.8027 28.1486 25.4883 27.845L20 22.3567L14.5117 27.845C14.1991 28.1575 13.7753 28.333 13.3333 28.333C12.8914 28.333 12.4675 28.1575 12.155 27.845Z" fill="#4C4F55"/>
                        </svg>
                    </div>
                    <div className={classes.menuText1}>{texts[lang].withdraw.text3}</div>
                    <div className={classes.menuText2}>{texts[lang].withdraw.text4} {history?.total}</div>
                    <div className={classes.menuText3}>{texts[lang].withdraw.text5}</div>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <td className={classes.menuText4}>№</td>
                                <td className={classes.menuText4}>{texts[lang].withdraw.text6}</td>
                                <td className={classes.menuText4}>{texts[lang].withdraw.text7}</td>
                            </tr>
                        </thead>
                        <tbody>
                        {history?.user_requests.filter(item=>item.status==="REQUEST").map((item, number) =>
                            <tr key={number}>
                                <td className={classes.menuText5}>{number+1}</td>
                                <td className={classes.menuText5}>{item.amount}</td>
                                <td className={classes.menuText5}>{item.queue_num}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <div className={classes.menuText3}>{texts[lang].withdraw.text8}</div>
                    <table className={classes.table2}>
                        <thead>
                            <tr>
                                <td className={classes.menuText4}>№</td>
                                <td className={classes.menuText4}>{texts[lang].withdraw.text6}</td>
                            </tr>
                        </thead>
                        <tbody>
                        {history?.user_requests.filter(item=>item.status!=="REQUEST").map((item, number) =>
                            <tr key={number}>
                                <td className={classes.menuText6}>{number+1}</td>
                                <td className={classes.menuText6}>{item.amount}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </CSSTransition>
            <Notificate text={texts[lang].notificate.text3} open={notification}/>
            <Notificate text={texts[lang].notificate.text4} open={notification2}/>
        </>
    );
};

export default Withdraw;