export const texts = {
    ru: {
        start:{
            text1:'Refetron марафон',
            text2:'твоей вершины'
        },
        bonuses: {
            bonus: 'Бонусы',
            text1: 'Крипто- кошелек',
            text2: 'Крипто- биржа',
            text3: 'Мотиватор органайзер',
            day: 'дней',
            hour: 'часов',
            minute: 'минут',
            menu1: 'Крипто-кошелёк',
            menu1_1: 'Получите 30% бонус при выводе монет через данный крипто-кошелёк',
            menu2: 'Крипто-биржа',
            menu2_1: 'Биржевой функционал и инструменты позволяющие увеличить капитал в',
            menu3: 'Органайзер мотиватор',
            menu3_1: 'с искуственным интелектом',
            menu3_2: 'вместо'
        },
        balance: {
            text1: 'Баланс',
            text2: 'Коинов'
        },
        button: {
            text1: 'Пополнить на 100 коинов',
            text2: 'Вывести'
        },
        menu: {
            home: 'Home',
            friends: 'Пригласить друзей',
            profit: 'Прокачать прибыль',
            map: 'Дорожная карта'
        },
        politics: {
            text1: 'Политика конфиденциальности',
            text2: 'Условия использования'
        },
        settings: {
            address: 'Адрес получателя USDT (BEP20)',
            text: 'Вывести по активации крипто-кошелька +30% к доходности',
            save: 'Сохранить'
        },
        referrals: {
            text1: 'Мои рефералы:',
            text2: 'Уровень',
            text3: 'Уровни реферальной программы',
            text4: 'участников - ',
            text4_1: 'коинов',
            text5: 'каждый ваш реферал приносит вам еще больше коинов',
            text6: 'Пригласить друзей'
        },
        roadmap: {
            text1: '1000 000 коинов в три этапа:',
            text2: 'Пополнил баланс на 100 монет',
            text3: 'Активировано участие в приложении',
            text4: 'Активация участия в приложении',
            text5: 'Пригласил 10 друзей  в приложение.',
            text6: 'Активированы реферальные вознаграждений',
            text7: 'Активация реферальных вознаграждений',
            text8: 'Произвел 20 подписок на каналы.',
            text9: 'Активировано увеличенное вознаграждение',
            text10: 'Активация удвоенных вознаграждений',
        },
        profit: {
            text1: 'Мои подписки:',
            text2: 'Реферальное вознаграждение',
            text3: 'коинов',
            text4: 'Подписаться',
            text5: 'Проверить подписку'
        },
        notificate: {
            text1: 'Ссылка скопирована',
            text2: 'Пополни баланс на 100 коинов для активации аккаунта!',
            text3: 'Для выполнения вывода средств необходимо указать адрес USDT BSC в настройках вашего аккаунта.',
            text4: 'Вы не можете вывести средства, так как ваша сумма на счету ниже минимально допустимого уровня.',
            text5: 'Ошибка проверки подписки.'
        },
        withdraw:{
            text1: 'Баланс',
            text2: 'минимальная сумма для вывода ',
            text3: 'Статус вывода',
            text4: 'Всего заявок -',
            text5: 'Ваши заявки в процессе обработки:',
            text6: 'Сумма в USDT',
            text7: 'Очередность',
            text8: 'Успешные заявки:',
            text9: 'Курс вывода',
            text10: 'К выводу',
        },
        addBalance:{
            text1: 'Выбор платёжной системы'
        }
    },
    eng:{
        start:{
            text1:'Refetron marathon',
            text2:'of your peak'
        },
        bonuses:{
            bonus:'Bonuses',
            text1:'Crypto wallet',
            text2:'Crypto exchange',
            text3:'Motivator organizer',
            day:'days',
            hour:'hours',
            minute:'minutes',
            menu1:'Crypto wallet',
            menu1_1:'Get a 30% bonus when withdrawing coins through this crypto wallet',
            menu2:'Crypto exchange',
            menu2_1:'Exchange functionality and tools to increase capital in',
            menu3:'Motivator organizer',
            menu3_1:'with artificial intelligence',
            menu3_2:'instead of'
        },
        balance: {
            text1: 'Balance',
            text2: 'Coins'
        },
        button:{
            text1:'Top up by 100 coins',
            text2:'Withdraw'
        },
        menu:{
            home:'Home',
            friends:'Invite friends',
            profit:'Boost profit',
            map:'Roadmap'
        },
        politics:{
            text1:'Privacy policy',
            text2:'Terms of use'
        },
        settings:{
            address:'Recipient address USDT (BEP20)',
            text:'Withdraw with crypto wallet activation +30% to profitability',
            save:'Save'
        },
        referrals:{
            text1:'My referrals:',
            text2:'Level',
            text3:'Levels of the referral program',
            text4:'participants - ',
            text4_1:'coins',
            text5:'each of your referrals brings you even more coins',
            text6:'Invite friends'
        },
        roadmap:{
            text1:'1,000,000 coins in three stages:',
            text2:'Top up balance by 100 coins',
            text3:'Participation in the app activated',
            text4: 'Activation of participation in the app',
            text5: 'Invited 10 friends to the app.',
            text6: 'Referral rewards activated',
            text7: 'Activation of referral rewards',
            text8: 'Made 20 subscriptions to channels.',
            text9: 'Increased reward activated',
            text10: 'Activation of doubled rewards'
        },
        profit:{
            text1: 'My subscriptions',
            text2: 'Referral reward',
            text3: 'coins',
            text4: 'Subscribe',
            text5: 'Check subscription'
        },
        notificate:{
            text1:'Link copied',
            text2: 'Top up your balance with 100 coins to activate your account!',
            text3: 'To proceed with the withdrawal, you need to specify your USDT BSC address in your account settings.',
            text4: 'You cannot withdraw funds as your account balance is below the minimum required level.',
            text5: 'Error checking subscription.'
        },
        withdraw:{
            text1: 'Balance',
            text2: 'minimum withdrawal amount ',
            text3: 'Withdrawal status',
            text4: 'Total requests -',
            text5: 'Your requests are being processed:',
            text6: 'Amount USDT',
            text7: 'Sequence',
            text8: 'Successful requests:',
            text9: 'Withdrawal course',
            text10: 'To withdrawal',
        },
        addBalance:{
            text1: 'Selecting a payment system'
        }
    }
}