import React, {useEffect, useState} from 'react';
import classes from "./referrals.module.css";
import {texts} from "../../../../translate";
import coin from '../../../../images/coin.png'
import InfoRefs from "../infoRefs/infoRefs";
import {getReferrals} from "../../../../utils/api";
import {useTelegram} from "../../../../hooks/useTelegram";
import {CSSTransition} from "react-transition-group";
import Notificate from "../notificate/notificate";
import {MEDIA_URL} from "../../../../config";

const Referrals = ({lang}) => {
    const {user} = useTelegram()
    const [openInfo, setOpenInfo] = useState(false)
    const [data, setData] = useState({
        "refferals": [
           /* {
                "id": "e3a3429c-c9ff-43a6-af98-3bf80dba1326",
                "telegram_id": 6677635691,
                "is_active": true,
                "avatar": "6e711d03366349d9871a79f810e1a535_6677635691.jpg",
                "username": "c_vs_d",
                "first_name": "Cats&Dogs",
                "last_name": null,
                "level": 1,
                "num_refferals": 0,
                "refferal_reward": 10
            }*/
        ],
        "refferal_levels": [
            {
                "level": 1,
                "num_refferals": 0,
                "max_num_refferals": 10,
                num_coins: 10
            },
            {
                "level": 2,
                "num_refferals": 0,
                "max_num_refferals": 100,
                num_coins: 5
            },
            {
                "level": 3,
                "num_refferals": 0,
                "max_num_refferals": 1000,
                num_coins: 5
            },
            {
                "level": 4,
                "num_refferals": 0,
                "max_num_refferals": 10000,
                num_coins: 5
            },
            {
                "level": 5,
                "num_refferals": 0,
                "max_num_refferals": 100000,
                num_coins: 5
            }
        ]
    })

    const refValue = window
        .btoa(user?.id + "")
        .split("")
        .map((i) => (i === "=" ? "" : i))
        .join("");

    useEffect(()=>{
        const getReferralsF = async ()=>{
            const res = await getReferrals()
            if(res.error){
                console.log(res)
            }
            else{
                setData(res)
            }
        }
        getReferralsF()
    },[])

    const copuFn = (text) => {
        //navigator.clipboard.writeText(text);
        //setNotification(true);
        //setTimeout(() => setNotification(false), 2000);
        let textAreaValue = lang==='ru' ? 'Refetron – 💰 заработай 1,000,000 монет! Это твой инструмент для роста личного капитала 🚀. ' +
            'Включает: инвестиции 📈, дивиденды 💵, реферальные возможности 🤝 и развитие коммуникативных навыков 🗣️.' : 'Refetron – 💰 earn 1,000,000 coins! Your tool for personal capital growth 🚀. ' +
            'Includes: investments 📈, dividends 💵, referral opportunities 🤝, and communication skills development 🗣️.'
        window.open(
            `https://t.me/share/url?url=${text}&text=${textAreaValue}`
        );
    };

    function getColor(level){
        if(level?.num_refferals === 0)
            return 0
        if(level?.num_refferals < level?.max_num_refferals)
            return .5
        return 1
    }

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.block1}>
                    <div className={classes.block1_1}>
                        <div className={classes.text1Block}>
                            <div className={classes.text1}>{texts[lang].referrals.text1}</div>
                            <div className={classes.text1_1}><span className={classes.text1_2}>{data.refferal_levels[0].num_refferals}</span> / 10</div>
                        </div>
                        <div className={classes.stars}>
                            {data.refferal_levels.map(item=>
                                <div className={classes.star} key={item.level}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                        <path d="M12.3139 0L16.5198 6.33902L23.8483 8.38018L19.1192 14.3391L19.4426 21.9396L12.3139 19.2834L5.18534 21.9396L5.50869 14.3391L0.779617 8.38018L8.10807 6.33902L12.3139 0Z" fill={'url(#Gradient'+item.level+')'}/>
                                        <linearGradient id={"Gradient"+item.level}>
                                            <stop offset={getColor(item)} stopColor="#B18B5B" />
                                            <stop offset={getColor(item)} stopColor="#4B4F55" />
                                        </linearGradient>
                                    </svg>
                                    <div className={classes.starsNumber} style={{left:item.level===1?'10px':''}}>{item.level}</div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                    <svg className={classes.info} onClick={()=>setOpenInfo(!openInfo)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.5" d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C12.9993 7.71733 12.9033 7.48 12.712 7.288C12.5207 7.096 12.2833 7 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C10.9993 8.28267 11.0953 8.52033 11.288 8.713C11.4807 8.90567 11.718 9.00133 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22Z" fill="white"/>
                    </svg>
                </div>
                {!openInfo ? <div className={classes.refBlocks}>
                    {data.refferals.map(item=>
                        <div key={item.id} className={classes.refBlock}>
                            <div className={classes.refBlock1}>
                                {item.avatar ? <img alt={''} src={MEDIA_URL+item.avatar} className={classes.refImg}/>:
                                    <div className={classes.avatarImage2}>{item.first_name.slice(0,1)}</div>
                                }
                                <div className={classes.refName}>{item.first_name} {item.last_name}</div>
                                <div className={classes.coins}>
                                    <img alt={''} className={classes.coin} src={coin}/>
                                    +{item.refferal_reward}
                                </div>
                            </div>
                            <div className={classes.refLevel}>
                                {texts[lang].referrals.text2}
                                <div className={classes.star}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" fill="none">
                                        <path d="M12.7176 0L17.1279 6.64722L24.8127 8.78763L19.8537 15.0362L20.1928 23.0063L12.7176 20.2209L5.24237 23.0063L5.58145 15.0362L0.622442 8.78763L8.30721 6.64722L12.7176 0Z" fill="#B18B5B"/>
                                    </svg>
                                    <div className={classes.starsNumber} style={{left:item.level===1?'10px':'9px'}}>{item.level}</div>
                                </div>
                            </div>
                            <div className={classes.refCountRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M13.3334 14.1666V15.8333H1.66675V14.1666C1.66675 14.1666 1.66675 10.8333 7.50008 10.8333C13.3334 10.8333 13.3334 14.1666 13.3334 14.1666ZM10.4167 6.24995C10.4167 5.67309 10.2457 5.10918 9.9252 4.62954C9.60471 4.14989 9.14919 3.77606 8.61624 3.5553C8.08329 3.33455 7.49685 3.27679 6.93107 3.38933C6.36529 3.50187 5.84559 3.77965 5.43769 4.18755C5.02978 4.59546 4.752 5.11516 4.63946 5.68094C4.52692 6.24671 4.58468 6.83316 4.80543 7.36611C5.02619 7.89906 5.40002 8.35458 5.87967 8.67507C6.35931 8.99556 6.92322 9.16662 7.50008 9.16662C8.27363 9.16662 9.01549 8.85932 9.56248 8.31234C10.1095 7.76536 10.4167 7.0235 10.4167 6.24995ZM13.2834 10.8333C13.7957 11.2297 14.2149 11.7336 14.5115 12.3095C14.8081 12.8854 14.9748 13.5193 15.0001 14.1666V15.8333H18.3334V14.1666C18.3334 14.1666 18.3334 11.1416 13.2834 10.8333ZM12.5001 3.33328C11.9266 3.33062 11.3657 3.50206 10.8917 3.82495C11.3979 4.53223 11.6701 5.38019 11.6701 6.24995C11.6701 7.11971 11.3979 7.96767 10.8917 8.67495C11.3657 8.99784 11.9266 9.16928 12.5001 9.16662C13.2736 9.16662 14.0155 8.85932 14.5625 8.31234C15.1095 7.76536 15.4167 7.0235 15.4167 6.24995C15.4167 5.4764 15.1095 4.73454 14.5625 4.18755C14.0155 3.64057 13.2736 3.33328 12.5001 3.33328Z" fill="white"/>
                                </svg>
                                {item.num_refferals}
                            </div>
                        </div>
                    )}
                </div> :
                <InfoRefs lang={lang} data={data}/> }
            </div>
            <div className={classes.button} onClick={() =>
                copuFn(`https://t.me/RefeTron_Bot/app?startapp=${user.id}`)
            }>{texts[lang].referrals.text6}</div>
        </div>
    );
};

export default Referrals;