import {API_URL} from "../config";
const token = window.Telegram?.WebApp?.initData

export async function register(inviter_id){
    let data = {
        inviter_id: inviter_id,
    }
    let response = await fetch(API_URL+'register',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getMe(){
    let response = await fetch(API_URL+'me',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function getSocialLinks(){
    let response = await fetch(API_URL+'social_links',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function saveMe(address, flag){
    let data = {
        crypto_address: address,
        automate_withdrawal: flag
    }
    let response = await fetch(API_URL+'update_user_setting',{
        method:'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getReferrals(){
    let response = await fetch(API_URL+'user_refferals',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function getLinks(){
    let response = await fetch(API_URL+'channel_subscriptions',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function topUpBalance(){
    let response = await fetch(API_URL+'top_up_balance_mock',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function subscribeChannel(id_channel){
    let data = {
        channel_subscription_id: id_channel,
    }
    let response = await fetch(API_URL+'subscribe_channel',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function withdrawalRequest(amount){
    let data = {
        amount: amount,
    }
    let response = await fetch(API_URL+'withdrawal_request',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function userWithdrawalRequests(){
    let response = await fetch(API_URL+'user_withdrawal_requests',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function plisio(){
    let response = await fetch(API_URL+'top_up_balance/plisio',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function cryptocloud(){
    let response = await fetch(API_URL+'top_up_balance/cryptocloud',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function stars(){
    let response = await fetch(API_URL+'top_up_balance/telegram',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}